/*
 This file is part of GNU Taler
 (C) 2025 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU Affero Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU Affero Public License for more details.

 You should have received a copy of the GNU Affero Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { format } from "date-fns";
import {
  DoubleColumnFormDesign,
  InternationalizationAPI,
} from "../../index.browser.js";
import { TalerFormAttributes } from "@gnu-taler/taler-util";

/**
 * Design of the vqf_902_1_officer form.
 */
export function VQF_902_1_officer(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  const today = format(new Date(), "yyyy-MM-dd");

  return {
    type: "double-column",
    sections: [
      {
        title: i18n.str`Acceptance of business relationship`,
        fields: [
          {
            id: TalerFormAttributes.ACCEPTANCE_DATE,
            label: i18n.str`Date (conclusion of contract):`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            defaultValue: today,
            required: true,
          },
          {
            id: TalerFormAttributes.ACCEPTANCE_METHOD,
            label: i18n.str`Accepted via:`,
            type: "choiceStacked",
            choices: [
              {
                value: "FACE_TO_FACE",
                label: i18n.str`Face to face`,
              },
              {
                value: "AUTHENTICATED_COPY",
                label: i18n.str`Authenticated copy of identification document`,
              },
              {
                value: "RESIDENTIAL_ADDRESS_VALIDATED",
                label: i18n.str`Residentail address validated`,
              },
            ],
            required: true,
          },
          {
            id: TalerFormAttributes.ACCEPTANCE_FURTHER_INFO,
            label: i18n.str`Further information:`,
            type: "textArea",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Evaluation with regard to embargo procedures/terrorism lists on establishing the business relationship`,
        description: i18n.str`Verification whether the customer, beneficial owners of the assets, controlling persons, authorized representatives or other involved persons are listed on an embargo/terrorism list.`,
        fields: [
          {
            id: TalerFormAttributes.EMBARGO_TERRORISM_CHECK_RESULT,
            label: i18n.str`Embargo/terrorism status:`,
            type: "choiceStacked",
            required: true,
            choices: [
              {
                label: i18n.str`Not listed on embargo/terrorism list.`,
                value: "NOT_LISTED",
              },
              {
                label: i18n.str`Listed on embargo/terrorism list.`,
                value: "LISTED",
              },
            ],
          },
          {
            id: TalerFormAttributes.EMBARGO_TERRORISM_INFO,
            label: i18n.str`Embargo/terrorism information:`,
            type: "textArea",
            hide(value, root): boolean {
              return (
                root[TalerFormAttributes.EMBARGO_TERRORISM_CHECK_RESULT] !==
                "LISTED"
              );
            },
            required: true,
          },
          {
            id: TalerFormAttributes.EMBARGO_TERRORISM_CHECK_DATE,
            label: i18n.str`Verification date`,
            type: "isoDateText",
            required: true,
            defaultValue: today,
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
          },
        ],
      },
      {
        title: i18n.str`Supplemental File Upload`,
        description: i18n.str`Optional supplemental information for the establishment of the business relationship with the customer.`,
        fields: [
          {
            id: TalerFormAttributes.SUPPLEMENTAL_FILES_LIST,
            label: i18n.str`Supplemental files`,
            type: "array",
            labelFieldId: "FILE.FILENAME",
            required: false,
            fields: [
              {
                id: TalerFormAttributes.DESCRIPTION,
                label: i18n.str`Description`,
                type: "textArea",
                required: true,
              },
              {
                id: TalerFormAttributes.FILE,
                label: i18n.str`File (PDF)`,
                type: "file",
                accept: "application/pdf",
                required: true,
              },
            ],
          },
        ],
      },
    ],
  };
}
