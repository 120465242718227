/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  TalerFormAttributes,
  TalerProtocolDuration,
} from "@gnu-taler/taler-util";
import type {
  InternationalizationAPI,
  SingleColumnFormDesign,
  UIFormElementConfig,
} from "@gnu-taler/web-util/browser";

const TALER_SCREEN_ID = 107;

function filterUndefined<T>(ar: Array<T | undefined>): Array<T> {
  return ar.filter((a): a is T => !!a);
}
export type AcceptTermOfServiceContext = {
  tos_url: string;
  provider_name?: string;
  expiration_time?: TalerProtocolDuration;
  successor_measure?: string;
};

// Example context
// {
//   "tos_url":"https://exchange.taler-ops.ch/terms",
//   "provider_name":"Taler Operations AG",
//   "expiration_time":{"d_us": 157680000000000},
//   "successor_measure":"accept-tos"
// }

/**
 *
 * @param i18n
 * @param context
 * @returns
 */
export const acceptTos = (
  i18n: InternationalizationAPI,
  context: AcceptTermOfServiceContext,
): SingleColumnFormDesign => ({
  type: "single-column" as const,
  fields: filterUndefined<UIFormElementConfig>([
    {
      type: "htmlIframe",
      label: context?.provider_name ?? `Link`,
      url: context.tos_url,
    },
    // {
    //   type: "download-link",
    //   id: TalerFormAttributes.DOWNLOADED_TERMS_OF_SERVICE,
    //   url: context.tos_url,
    //   label: "Download text version",
    //   media: "text/plain",
    //   required: true,
    //   help: i18n.str`You must download to proceed`
    // },
    {
      type: "download-link",
      id: TalerFormAttributes.DOWNLOADED_TERMS_OF_SERVICE,
      url: context.tos_url,
      label: "Download PDF version",
      required: true,
      media: "application/pdf",
      help: i18n.str`You must download to proceed`
    },
    {
      type: "toggle",
      id: TalerFormAttributes.ACCEPTED_TERMS_OF_SERVICE,
      required: true,
      label: i18n.str`Do you accept terms of service?`,
    },
  ]),
});
