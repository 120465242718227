import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { RenderAddon } from "./fields/InputLine.js";
import { Addon, UIFormProps } from "./FormProvider.js";
import { noHandlerPropsAndNoContextForField } from "../index.browser.js";

interface Props {
  label: TranslatedString;
  url: string;
  media?: string;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: Addon;
  after?: Addon;
}

export function InputDownloadLink(props: Props & UIFormProps<boolean>): VNode {
  const {
    media,
    url,
    label,
    tooltip,
    help,
    required,
    disabled,
    before,
    after,
  } = props;
  const { value, onChange, error } =
    props.handler ?? noHandlerPropsAndNoContextForField(props.name);

  return (
    <div class="col-span-6" data-downloaded={!!value}>
      {before !== undefined && <RenderAddon addon={before} />}
      <a
        href="#"
        onClick={(e) => {
          onChange(true);
          return (
            fetch(url, {
              headers: {
                "Content-Type": media ?? "text/html",
              },
              cache: "no-cache",
            })
              // .then((r) => r.text())
              .then((r) => r.arrayBuffer())
              .then((r) => {
                const b64 = window.btoa(
                  new Uint8Array(r).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    "",
                  ),
                );

                const a = document.createElement("a");
                a.href = `data:${media ?? "text/html"};base64,${b64}`;
                a.download = "";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                return;
              })
          );
        }}
        media={media}
        download
      >
        {label}
      </a>
      {required ? (
        <span class="text-sm leading-6 text-red-600 pl-2">*</span>
      ) : undefined}

      {after !== undefined && <RenderAddon addon={after} />}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
