/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AbsoluteTime,
  AmountJson,
  PaytoUri,
  TalerCoreBankErrorsByMethod,
  TalerError,
  WithdrawUriResult,
} from "@gnu-taler/taler-util";
import { Loading, RouteDefinition, utils } from "@gnu-taler/web-util/browser";
import { VNode } from "preact";
import { ErrorLoadingWithDebug } from "../../components/ErrorLoadingWithDebug.js";
import { useComponentState } from "./state.js";
import {
  AbortedView,
  ConfirmedView,
  FailedView,
  InvalidPaytoView,
  InvalidReserveView,
  InvalidWithdrawalView,
  NeedConfirmationView,
  ReadyView,
} from "./views.js";

export interface Props {
  currency: string;
  onAuthorizationRequired: () => void;
  routeClose: RouteDefinition;
  onAbort: () => void;
  focus?: boolean;
  routeHere: RouteDefinition<{ wopid: string }>;
}

export type State =
  | State.Loading
  | State.LoadingError
  | State.Ready
  | State.Failed
  | State.Aborted
  | State.Confirmed
  | State.InvalidPayto
  | State.InvalidWithdrawal
  | State.InvalidReserve
  | State.NeedConfirmation;

export namespace State {
  export interface Loading {
    status: "loading";
    error: undefined;
  }

  export interface Failed {
    status: "failed";
    error: TalerCoreBankErrorsByMethod<"createWithdrawal">;
  }

  export interface LoadingError {
    status: "loading-error";
    error: TalerError;
  }

  /**
   * Need to open the wallet
   */
  export interface Ready {
    status: "ready";
    error: undefined;
    uri: WithdrawUriResult;
    focus?: boolean;
    onAbort: () => Promise<
      TalerCoreBankErrorsByMethod<"abortWithdrawalById"> | undefined
    >;
    routeClose: RouteDefinition;
  }

  export interface InvalidPayto {
    status: "invalid-payto";
    error: undefined;
    payto: string | undefined;
  }
  export interface InvalidWithdrawal {
    status: "invalid-withdrawal";
    error: undefined;
    uri: string;
  }
  export interface InvalidReserve {
    status: "invalid-reserve";
    error: undefined;
    reserve: string | undefined;
  }
  export interface NeedConfirmation {
    status: "need-confirmation";
    onAuthorizationRequired: () => void;
    account: string;
    routeHere: RouteDefinition<{ wopid: string }>;
    onAbort:
      | undefined
      | (() => Promise<
          TalerCoreBankErrorsByMethod<"abortWithdrawalById"> | undefined
        >);
    onConfirm:
      | undefined
      | (() => Promise<
          TalerCoreBankErrorsByMethod<"confirmWithdrawalById"> | undefined
        >);
    error: undefined;
    details: {
      account: PaytoUri;
      reserve: string;
      username: string;
      amount?: AmountJson;
    };
    id: string;
  }
  export interface Aborted {
    status: "aborted";
    error: undefined;
    routeClose: RouteDefinition;
  }
  export interface Confirmed {
    status: "confirmed";
    error: undefined;
    routeClose: RouteDefinition;
  }
}

export interface Transaction {
  negative: boolean;
  counterpart: string;
  when: AbsoluteTime;
  amount: AmountJson | undefined;
  subject: string;
}

const viewMapping: utils.StateViewMap<State> = {
  loading: Loading,
  failed: FailedView,
  "invalid-payto": InvalidPaytoView,
  "invalid-withdrawal": InvalidWithdrawalView,
  "invalid-reserve": InvalidReserveView,
  "need-confirmation": NeedConfirmationView,
  aborted: AbortedView,
  confirmed: ConfirmedView,
  "loading-error": ErrorLoadingWithDebug,
  ready: ReadyView,
};

export const OperationState: (p: Props) => VNode = utils.compose(
  (p: Props) => useComponentState(p),
  viewMapping,
);
