/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  Amounts,
  HttpStatusCode,
  TalerError,
  assertUnreachable,
  parsePaytoUri,
} from "@gnu-taler/taler-util";
import { useAccountDetails } from "../../hooks/account.js";
import { Props, State } from "./index.js";

export function useComponentState({
  account,
  tab,
  routeChargeWallet,
  routeCreateWireTransfer,
  routePublicAccounts,
  routeSolveSecondFactor,
  routeOperationDetails,
  routeWireTransfer,
  routeCashout,
  onOperationCreated,
  onClose,
  routeClose,
  onAuthorizationRequired,
}: Props): State {
  const result = useAccountDetails(account);

  if (!result) {
    return {
      status: "loading",
      error: undefined,
    };
  }

  if (result instanceof TalerError) {
    return {
      status: "loading-error",
      error: result,
    };
  }

  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.Unauthorized:
        return {
          status: "login",
          onAuthorizationRequired,
          reason: "forbidden",
        };
      case HttpStatusCode.NotFound:
        return {
          status: "login",
          onAuthorizationRequired,
          reason: "not-found",
        };
      default: {
        assertUnreachable(result);
      }
    }
  }

  const { body: data } = result;

  const balance = Amounts.parseOrThrow(data.balance.amount);

  const debitThreshold = Amounts.parseOrThrow(data.debit_threshold);
  const payto = parsePaytoUri(data.payto_uri);

  if (
    !payto ||
    !payto.isKnown ||
    (payto.targetType !== "iban" && payto.targetType !== "x-taler-bank")
  ) {
    return {
      status: "invalid-iban",
      error: data,
    };
  }

  const balanceIsDebit = data.balance.credit_debit_indicator == "debit";
  const limit = balanceIsDebit
    ? Amounts.sub(debitThreshold, balance).amount
    : Amounts.add(balance, debitThreshold).amount;

  const positiveBalance = balanceIsDebit
    ? Amounts.zeroOfAmount(balance)
    : balance;

  return {
    status: "ready",
    onOperationCreated,
    error: undefined,
    tab,
    routeCashout,
    routeOperationDetails,
    routeCreateWireTransfer,
    routePublicAccounts,
    routeSolveSecondFactor,
    onAuthorizationRequired,
    onClose,
    routeClose,
    routeChargeWallet,
    routeWireTransfer,
    account,
    limit,
    balance: positiveBalance,
  };
}
