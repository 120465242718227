import {
  DoubleColumnFormDesign,
  InternationalizationAPI,
} from "../../index.browser.js";
import { TalerFormAttributes } from "@gnu-taler/taler-util";

export function VQF_902_5(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    sections: [
      {
        title: i18n.str`Business activity`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_PROFESSION,
            label: i18n.str`Profession, business activities, etc. (former, current, potentially planned)`,
            type: "textArea",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Financial circumstances`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_INCOME,
            label: i18n.str`Income and assets, liabilities (estimated)`,
            type: "textArea",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Origin of the deposited assets involved`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_HAVE_ASSETS,
            label: i18n.str`Will the the customer deposit assets with Taler Operations AG?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
          },
          {
            id: TalerFormAttributes.BIZREL_ORIGIN_NATURE,
            label: i18n.str`Nature, amount and currency of deposited assets.`,
            type: "textArea",
            required: true,
            hide(value, root) {
              return !root[TalerFormAttributes.BIZREL_HAVE_ASSETS];
            },
          },
          {
            id: TalerFormAttributes.BIZREL_ORIGIN_CATEGORY,
            label: i18n.str`Category`,
            type: "choiceStacked",
            choices: [
              { label: i18n.str`Savings`, value: "SAVINGS" },
              {
                label: i18n.str`Own business operations`,
                value: "OWN_BUSINESS",
              },
              { label: i18n.str`Inheritance`, value: "INHERITANCE" },
              { label: i18n.str`Other`, value: "OTHER" },
            ],
            required: true,
            hide(value, root) {
              return !root[TalerFormAttributes.BIZREL_HAVE_ASSETS];
            },
          },
          {
            id: TalerFormAttributes.BIZREL_ORIGIN_CATEGORY_OTHER,
            type: "text",
            label: i18n.str`Category clarification`,
            required: true,
            hide(value, root) {
              return (
                root[TalerFormAttributes.BIZREL_ORIGIN_CATEGORY] !== "OTHER"
              );
            },
          },
          {
            id: TalerFormAttributes.BIZREL_ORIGIN_DETAIL,
            label: i18n.str`Detail description of the origings/economical background of the assets involved in the business relationship`,
            type: "textArea",
            required: false,
            hide(value, root) {
              return (
                root[TalerFormAttributes.BIZREL_ORIGIN_CATEGORY] !== "OTHER"
              );
            },
          },
        ],
      },
      {
        title: i18n.str`Nature and purpose of the business relationship`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_PURPOSE,
            label: i18n.str`Purpose of the business relationship`,
            type: "textArea",
            required: false,
          },
          {
            id: TalerFormAttributes.BIZREL_DEVELOPMENT,
            label: i18n.str`Information on the planned development of the business relationship and the assets`,
            type: "textArea",
            required: false,
          },
          {
            id: TalerFormAttributes.BIZREL_FINANCIAL_VOLUME,
            label: i18n.str`Detail on usual business volume`,
            type: "textArea",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Relationship with third parties`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_THIRDPARTY_RELATIONSHIP,
            label: i18n.str`Relation of the customer to the beneficial owner, controlling persons, authorised signatories and other persons involved in the business relationship`,
            type: "textArea",
            required: false,
          },
          {
            id: TalerFormAttributes.BIZREL_THIRDPARTY_AMLA_FILES,
            label: i18n.str`Relation to other AMLA-Files`,
            type: "textArea",
            required: false,
          },
          {
            id: TalerFormAttributes.BIZREL_THIRDPARTY_REFERENCES,
            label: i18n.str`Introducer / agents / references`,
            type: "textArea",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Further information`,
        fields: [
          {
            id: TalerFormAttributes.BIZREL_FURTHER_INFO,
            label: i18n.str`Other relevant information`,
            type: "textArea",
            required: false,
          },
        ],
      },
    ],
  };
}
