import {
  DoubleColumnFormDesign,
  InternationalizationAPI,
} from "../../index.browser.js";
import { countryNationalityList } from "../../utils/select-ui-lists.js";
import { TalerFormAttributes } from "@gnu-taler/taler-util";

export function VQF_902_9(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    title: i18n.str`Declaration of identity of the beneficial owner`,
    sections: [
      {
        title: "Information about submitter",
        fields: [
          {
            id: TalerFormAttributes.SUBMITTED_BY,
            label: i18n.str`Who is filling out the form?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                label: "Taler Operations staff",
                value: "AML_OFFICER",
              },
              {
                label: "The customer",
                value: "CUSTOMER",
              },
            ],
          },
        ],
      },
      {
        title: "Identity of the contracting partner",
        fields: [
          {
            id: TalerFormAttributes.IDENTITY_CONTRACTING_PARTNER,
            label: i18n.str`Contracting partner`,
            type: "textArea",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Beneficial owner details`,
        fields: [
          {
            id: TalerFormAttributes.IDENTITY_LIST,
            label: i18n.str`Beneficial owner(s)`,
            help: i18n.str`The person(s) listed below is/are the beneficial owner(s) of the assets involved in the business relationship. If the contracting partner is also the sole beneficial owner of the assets, the contracting partner's detail must be set out below`,
            type: "array",
            validator(persons) {
              if (!persons || persons.length < 1) {
                return i18n.str`Can't be empty`;
              }
              return undefined;
            },
            labelFieldId: TalerFormAttributes.FULL_NAME,
            fields: [
              {
                id: TalerFormAttributes.FULL_NAME,
                label: i18n.str`Full name`,
                type: "text",
                required: true,
              },
              {
                id: TalerFormAttributes.DOMICILE_ADDRESS,
                label: i18n.str`Domicile address`,
                type: "textArea",
                required: true,
              },
              {
                id: TalerFormAttributes.DATE_OF_BIRTH,
                label: i18n.str`Date of birth`,
                type: "isoDateText",
                placeholder: "dd/MM/yyyy",
                pattern: "dd/MM/yyyy",
                required: true,
              },
              {
                id: TalerFormAttributes.NATIONALITY,
                label: i18n.str`Nationality`,
                type: "selectOne",
                choices: countryNationalityList(i18n),
                required: true,
              },
            ],
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Signature(s)`,
        description: i18n.str`It is a criminal offence to deliberately provide false information on this form (article 251 of the Swiss Criminal Code, documents forgery)`,
        hide(root) {
          return root[TalerFormAttributes.SUBMITTED_BY] != "CUSTOMER";
        },
        fields: [
          {
            type: "caption",
            label: i18n.str`The contracting partner hereby undertakes to inform automatically of any changes to the information contained herein.`,
          },
          {
            id: TalerFormAttributes.SIGNATURE,
            label: i18n.str`Signature(s)`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.SIGN_DATE,
            label: i18n.str`Date`,
            type: "absoluteTimeText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Signed Declaration`,
        description: i18n.str`Signed declaration by the customer`,
        hide(root) {
          return root[TalerFormAttributes.SUBMITTED_BY] != "AML_OFFICER";
        },
        fields: [
          {
            type: "caption",
            label: i18n.str`The uploaded document must contain the customer's signature on the beneficial owner declaration.`,
          },
          {
            id: TalerFormAttributes.ATTACHMENT_SIGNED_DOCUMENT,
            label: i18n.str`Signed Document`,
            type: "file",
            accept: "application/pdf",
            required: true,
          },
        ],
      },
    ],
  };
}
